import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { EVENT_HISTORY_ACTION_TYPES } from 'Consts/eventsHistory';

import { parseQueryToObject } from 'Utils/querystring';
import { filterKeys } from 'Utils/object';
import { getFullName as getUserFullName } from 'Utils/user';
import { getActionTypeLabel } from 'Utils/eventHistory';
import { getFormattedDate } from 'Utils/date';

import StyledComponent from 'Components/core/StyledComponent';
import PaginatedList from 'Components/layoutAdmin/panel/PaginatedList';
import PaginatedListElement from 'Components/layoutAdmin/panel/PaginatedListElement';

export default class AdminEventsHistoryList extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            list: PropTypes.func.isRequired,
        }).isRequired,
        eventsHistoryList: PropTypes.object.isRequired,
        predefinedQuery: PropTypes.object,
    };
    static defaultProps = {
        predefinedQuery: {},
    };

    defaultQuery = {
        page: 1,
        search: '',
        orderBy: '',
        timestamp: undefined,
        actionType: undefined,
        ...(this.props.predefinedQuery || {}),  //eslint-disable-line react/destructuring-assignment
    };

    getQueryConfig = (props = this.props) => {
        const { location } = props;
        const { search } = location;
        const queryObject = parseQueryToObject(search, true);

        return filterKeys(
            this.defaultQuery,
            queryObject,
            Object.keys(this.defaultQuery)
        );
    }

    componentDidMount = () => {
        this.loadData();
    }

    componentDidUpdate = prevProps => {
        const previousQueryObject = this.getQueryConfig(prevProps);
        const queryObject = this.getQueryConfig();

        if (JSON.stringify(previousQueryObject) !== JSON.stringify(queryObject)) {
            this.loadData();
        }

        if (JSON.stringify(prevProps.predefinedQuery) !== JSON.stringify(this.props.predefinedQuery)) {  //eslint-disable-line react/destructuring-assignment
            this.loadData();
        }
    }

    loadData = () => {
        const { actions } = this.props;
        const queryObject = this.getQueryConfig();

        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(() => {
            actions.list({ ...queryObject });
        }, 500);
    }

    render() {
        const { eventsHistoryList, location, history } = this.props;

        return (
            <StyledComponent
                className="panel-events-history-list"
                styles={require('./styles')}
            >
                <PaginatedList
                    location={location}
                    history={history}
                    collection={eventsHistoryList}
                    title="Historia zmian wydarzenia"
                    queryConfig={this.getQueryConfig()}
                    onMapElement={element => (
                        <PaginatedListElement
                            key={element.id}
                            title={getActionTypeLabel(element).label}
                            additionals={[{
                                visible: true,
                                name: 'Data zdarzenia',
                                value: getFormattedDate(element.createdAt) || 'Brak',
                            }, {
                                visible: true,
                                name: 'Użytkownik',
                                value: element.creator && getUserFullName(element.creator).label || 'Brak',
                            }, {
                                visible: true,
                                name: 'Akcja systemu',
                                value: element.isSystemAction ? 'Tak' : 'Nie',
                            }]}
                        />
                    )}
                    filters={[{
                        name: 'actionType',
                        label: 'Typ zdarzenia',
                        type: 'select',
                        inputProps: {
                            options: [
                                {
                                    label: 'Wszystkie',
                                    value: '',
                                }, 
                                ...EVENT_HISTORY_ACTION_TYPES.map(option => ({ ...option, value: option.key })),
                            ],
                        },
                    }]}
                />
            </StyledComponent>
        );
    }
}
