export const EVENT_HISTORY_ACTION_TYPE_CREATED = 'created';
export const EVENT_HISTORY_ACTION_TYPE_UPDATED = 'updated';
export const EVENT_HISTORY_ACTION_TYPE_PLANNED = 'planned';
export const EVENT_HISTORY_ACTION_TYPE_REPLANNED = 'replanned';
export const EVENT_HISTORY_ACTION_TYPE_UNPLANNED = 'unplanned';
export const EVENT_HISTORY_ACTION_TYPE_CANCELED = 'canceled';
export const EVENT_HISTORY_ACTION_TYPE_JOIN_CONFIRMED = 'joinConfirmed';
export const EVENT_HISTORY_ACTION_TYPE_DONE_CONFIRMED = 'doneConfirmed';
export const EVENT_HISTORY_ACTION_TYPE_DELETED = 'deleted';

export const EVENT_HISTORY_ACTION_TYPES = [
    {
        key: EVENT_HISTORY_ACTION_TYPE_CREATED,
        label: 'Utworzony',
    }, {
        key: EVENT_HISTORY_ACTION_TYPE_UPDATED,
        label: 'Zaktualizowany',
    }, {
        key: EVENT_HISTORY_ACTION_TYPE_PLANNED,
        label: 'Zaplanowany',
    }, {
        key: EVENT_HISTORY_ACTION_TYPE_REPLANNED,
        label: 'Przełożony',
    }, {
        key: EVENT_HISTORY_ACTION_TYPE_UNPLANNED,
        label: 'Odwołany',
    }, {
        key: EVENT_HISTORY_ACTION_TYPE_CANCELED,
        label: 'Odwołany',
    }, {
        key: EVENT_HISTORY_ACTION_TYPE_JOIN_CONFIRMED,
        label: 'Dołączenie potwierdzone',
    }, {
        key: EVENT_HISTORY_ACTION_TYPE_DONE_CONFIRMED,
        label: 'Wykonanie potwierdzone',
    }, {
        key: EVENT_HISTORY_ACTION_TYPE_DELETED,
        label: 'Usunięty',
    },
];
