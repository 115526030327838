import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ADMIN_USERS_MANAGE, ADMIN_USER_OFFERS_MANAGE, ADMIN_EVENTS_MANAGE } from 'Consts/routes';
import { ACTIVITY_LOGS_DATA_TYPE_EVENT } from 'Consts/activityLogs';

import { withVariables } from 'Utils/string';
import { getFullName as getUserFullName } from 'Utils/user';

import StyledComponent from 'Components/core/StyledComponent';
import PageContent from 'Components/layout/panel/PageContent';
import Page from 'Components/layout/panel/Page';
import TabNavigation from 'Components/layout/panel/TabNavigation';
import Editor from 'Components/admin/events/Editor';
import HistoryList from 'Components/admin/eventsHistory/List';
import LogsList from 'Components/admin/activityLogs/List';

export const TAB_DATA = 'data';
export const TAB_HISTORY_LIST = 'historyList';
export const TAB_EVENT_LOGS = 'eventLogs';
export const TAB_EVENT_CYCLE = 'eventCycle';

export default class AdminEventsManage extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        match: PropTypes.shape({
            params: PropTypes.shape({
                id: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
        actions: PropTypes.shape({
            single: PropTypes.func.isRequired,
        }).isRequired,
        event: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            isLoaded: PropTypes.bool.isRequired,
            data: PropTypes.object,
        }),
    };
    static defaultProps = {};
    state = {};

    componentDidMount = () => {
        this.loadData();
    }

    componentDidUpdate = prevProps => {
        const { match } = this.props;
        const { params } = match;

        if (prevProps.match.params.id !== params.id) {
            this.loadData();
        }
    }

    loadData = () => {
        const { actions, match } = this.props;
        const { params } = match;

        actions.single({ id: params.id });
    }

    render() {
        const { event, location, history } = this.props;

        return (
            <StyledComponent
                className="admin-events-manage"
                styles={require('./styles')}
            >
                
                <Page
                    elementMode={true}
                    data={event}
                >
                    {event && event.data && (
                        <PageContent
                            breadcrumbs={[{
                                visible: Boolean(event.data.user),
                                to: withVariables(ADMIN_USERS_MANAGE.path, { id: event.data.user && event.data.user.id }),
                                label: `Użytkownik: ${event.data.user && getUserFullName(event.data.user).label}`,
                            },{
                                visible: Boolean(event.data.userOffer),
                                to: withVariables(ADMIN_USER_OFFERS_MANAGE.path, { id: event.data.userOffer && event.data.userOffer.id }),
                                label: `Zamówienie: ${event.data.userOffer && event.data.userOffer.name}`,
                            },{
                                to: withVariables(ADMIN_EVENTS_MANAGE.path, { id: event.data.id }, { tab: TAB_DATA }),
                                label: 'Edytuj wydarzenie',
                            }]}
                        >
                            <TabNavigation  
                                location={location}
                                history={history}
                                headline="Edytuj wydarzenie"
                                subHeadline={event.data.name}
                                tabs={[{
                                    key: TAB_DATA,
                                    label: 'Dane',
                                    children: (
                                        <Editor
                                            location={location}
                                            history={history}
                                            data={event.data}
                                        />
                                    ),
                                }, {
                                    key: TAB_HISTORY_LIST,
                                    label: 'Historia zmian',
                                    children: (
                                        <HistoryList
                                            location={location}
                                            history={history}
                                            predefinedQuery={{
                                                eventId: event.data.id,
                                            }}
                                        />
                                    ),
                                }, {
                                    visible: true,
                                    key: TAB_EVENT_LOGS,
                                    label: 'Logi',
                                    children: (
                                        <LogsList 
                                            location={location}
                                            history={history}
                                            predefinedQuery={{
                                                dataType: ACTIVITY_LOGS_DATA_TYPE_EVENT,
                                                dataId: event.data.id || undefined,
                                            }}
                                            onMapFilters={() => []}
                                        />
                                    ),
                                }]}
                            />
                        </PageContent>
                    )}
                </Page>     
            </StyledComponent>
        );
    }
}