import {
    EVENT_HISTORY_ACTION_TYPES,
} from 'Consts/eventsHistory';

export const getActionTypeLabel = eventHistory => {
    if(!eventHistory) {
        return { found: false, label: 'Nieznana' };
    }

    const { actionType } = eventHistory;
    const option = EVENT_HISTORY_ACTION_TYPES.find(eventHistoryActionType => eventHistoryActionType.key === actionType);
    if (!option) {
        return { found: false, label: 'Nieznana' };
    }

    return { found: true, label: option.label };
};
